import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import Img from 'gatsby-image';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';
import Hero from '../components/bg-img-hero-1';

const Home = (props) => {
  const { heroImage } = props.data;
  const { img1 } = props.data;
  return (
    <Layout bodyClass="page-home">
      <SEO
      title="Artists Choice - Wisconsin Framing Company"
      description="High Quality Custom Framing At Affordable Prices. We Offer In Person and Online/By-Mail Framing."
      />
      <Hero
      background={heroImage.childImageSharp.fluid}
      headline="20+ Years Of Framing Expertise"
      subhead="We are the Wisconsin framing company that works with your artistic approach."
      btnTxt="Inquire About Framing"
      url="/get-something-framed"
      />
      <div className="container pt-4 pb-4 pt-md-8 columns">
      <div className="column"><Img className="background is-transparent" fluid={img1.childImageSharp.fluid} /></div>
      <div className="column">
<h2> Thanks for showing interest in Artists Choice.</h2>
      <p>Here at Artists’ Choice, we specialize in all kinds of custom, professional framing and printing for Minneapolis & St. Paul, Wisconsin, and the surrounding area. Whether you have a trade show to prepare for, you’re redecorating your den with sports memorabilia, or you’re looking for high-end artwork frames, we can help you with all of it.</p>

      <p>We’re also experienced in working with custom tiles & mosaics, as well as the new shadowbox trend. Shadowboxes are great for vamping up a space with a modern, clean décor, and they offer a budget-friendly option. Custom tiles & mosaics are perfect gift items, and make great centerpieces in a newly decorated space. Printing a photo on a ceramic tile is a unique, affordable gift that can be cherished and shown off for many years to come. Mosaics are great for displaying wedding photos, or bringing a special, artistic look to the photos you hang in your home.</p>

      <p>We started this company back in 1999, and our experience and knowledge in custom framing allows us to offer expert framing at reasonable prices. Artists' Choice is your one-stop shop for all of your framing and printing needs.</p>
      </div>
      </div>
      <div className="container pb-4  pb-md-8 border-top pt-4 ">
      <h2 className="pb-2">Our Custom Framing Process:</h2>
      <div className="tile is-ancestor">
      <div className="tile is-vertical is-parent">
      <div className="tile box columns ">
      <div className="has-text-centered	 p-2"><span className="is-size-2">1:</span></div>
      <div className="my-auto"> Schedule an appointment for framing consultation.  We also take walk-ins during our business hours.  If you need to come at a time other than our business hours, we will do our best to accommodate your needs.</div>
      </div>
      <div className="tile box">
      <div className="has-text-centered	 p-2"><span className="is-size-2">2:</span></div>
      <div className="my-auto">Our framing consultation includes free design guidance and recommendation of frame styles, mat colors, mounting of artwork, and glass types available.</div>
      </div>
      <div className="tile box">
      <div className="has-text-centered	 p-2"><span className="is-size-2">3:</span></div>
      <div className="my-auto">We can also do a consultation by email or phone.  We can email sample layouts to you with frame and mat corners to help you visualize the finished product.  We have found that this works much better than the framing software that is usually visually inaccurate and does not represent frame style or mat colors accurately.</div>
      </div>
      <div className="tile box">
      <div className="has-text-centered	 p-2"><span className="is-size-2">4:</span></div>
      <div className="my-auto">You can personally deliver your artwork to us, or send it via your favorite shipping company.</div>
      </div>
      <div className="tile box">
      <div className="has-text-centered	 p-2"><span className="is-size-2">6:</span></div>
      <div className="my-auto">All framing is done on site at our shop by the owners, Todd and Steffanie, 40 years combined framing experience.</div>
      </div>
      <div className="tile box">
      <div className="has-text-centered	 p-2"><span className="is-size-2">7:</span></div>
      <div className="my-auto">You will be notified when the framing is completed and can pick up at your convenience.  We can also ship the framed piece to you for a nominal fee.</div>
      </div>
      </div>
      </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    heroImage: file( relativePath: { eq: "wisconsin-framing-company.jpg" } ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img1: file( relativePath: { eq: "affordable-custom-framing-wisconsin.png" } ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Home;
